import { createAction, props } from "@ngrx/store";
import { SolicitudModel } from "../models/solicitud";

export const setSolicitud = createAction(
  "[Solicitud Component] Set solicitud",
  props<{ solicitud: SolicitudModel }>()
);

export const unsetSolicitud = createAction(
  "[Solicitud Component] Unset solicitud"
);
