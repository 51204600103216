import { createReducer, on } from "@ngrx/store";
import { ConsecutivoModel } from "../models/consecutivo.model";
import { setConsecutivo, unseConsecutivo } from "./consecutivo.actions";

export interface State {
  consecutivo: ConsecutivoModel;
}

export const initialState: State = {
  consecutivo: null,
};

const _consecutivoReducer = createReducer(
  initialState,
  on(setConsecutivo, (state, { consecutivo }) => ({
    ...state,
    consecutivo: { ...consecutivo },
  })),
  on(unseConsecutivo, (state) => ({
    ...state,
    consecutivo: initialState.consecutivo,
  }))
);

export function consecutivoReducer(state, action) {
  return _consecutivoReducer(state, action);
}
