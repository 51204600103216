import { createReducer, on } from "@ngrx/store";
import { PlantillaCorreo } from "../models/dtos/plantilla.correo.model";
import { setPlantilla, unsetPlantilla } from "./plantilla-correo.actions";

export interface State {
  plantilla: PlantillaCorreo;
}

export const initialState: State = {
  plantilla: null,
};

const _plantillaCorreoReducer = createReducer(
  initialState,
  on(setPlantilla, (state, { plantilla }) => ({
    ...state,
    plantilla: { ...plantilla },
  })),
  on(unsetPlantilla, (state) => ({
    ...state,
    plantilla: initialState.plantilla,
  }))
);

export function plantillaReducer(state, action) {
  return _plantillaCorreoReducer(state, action);
}
