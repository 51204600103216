import { createAction, props } from "@ngrx/store";
import { UserInfoModel } from "../models/user.info.model";

export const setUser = createAction(
  "[Auth] setUser",
  props<{ user: UserInfoModel }>()
);

export const unSetUser = createAction("[Auth] unSetUser");

export const setLoggging = createAction(
  "[Auth] setLoggging",
  props<{ loggging: boolean }>()
);

export const unSetLogging = createAction("[Auth] unSetLogging");
