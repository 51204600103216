import { createAction, props } from "@ngrx/store";
import { LogCatasia } from "../models/log.catasia.model";
import { LogEsri } from "./../models/log.esri.model";

export type ModosLogEsri = "respuestaCorta" | "peticion";

export const setLogEsriSelected = createAction(
  "[Sistema Component] Set logEsri Selected",
  props<{ logEsri: LogEsri }>()
);

export const unsetLogEsriSelected = createAction(
  "[Sistema Component] unSet logEsri Selected"
);

export const setLogEsriModo = createAction(
  "[Sistema Component] Set logEsri Modo",
  props<{ logEsriModo: string }>()
);

export const unsetLogEsriModo = createAction(
  "[Sistema Component] Set logEsri Modo"
);

export const setLogCatasiaSelected = createAction(
  "[Sistema Component] Set logCatasia Selected",
  props<{ logCatasia: LogCatasia }>()
);

export const unsetLogCatasiaSelected = createAction(
  "[Sistema Component] unSet logCatasia Selected"
);
