import { SolicitanteModel } from "./solicitante.model";
import { SistemaEnvioModel } from "./sistema.envio";
import { FormaPeticionModel } from "./forma.peticion";
import { SolicitanteTipo } from "./dtos/tipo.solicitante.model";
import { ContactoSolicitanteModel } from "./solicitante.contacto.model";

export class SolicitudModel {
  id: number;
  numSolicitud: string;
  formaPeticionId: number;
  formaPeticion: FormaPeticionModel;
  solicitudEstadoId: number;
  asunto: string;
  observacion: string;
  sistemaEnvioId: number;
  notificacionCorreo: boolean;
  sistemaEnvio: SistemaEnvioModel;
  radicador: string;
  radicadorId: number;
  fechaCreacion: Date;
  fechaRadicacion: Date;
  fechaFinDocs: Date;
  solicitanteId: number;
  solicitante: SolicitanteModel;
  solicitanteTipoId: number;
  solicitanteTipo: SolicitanteTipo;
  solicitud: {
    formaPeticionId: number;
    sistemaEnvioId: number;
    notificacionCorreo: boolean;
    referenciaExterna?: string;
  };
  iconClass: string;
  titleNameIcon: string;
  contacto: ContactoSolicitanteModel;
  fechaReferencia: any;
  referenciaExterna: string;

  constructor(solicitud?: SolicitudModel) {
    this.id = solicitud?.id;
    this.numSolicitud = solicitud?.numSolicitud;
    this.formaPeticionId = solicitud?.formaPeticionId;
    this.formaPeticion = solicitud?.formaPeticion;
    this.solicitudEstadoId = solicitud?.solicitudEstadoId;
    this.asunto = solicitud?.asunto;
    this.observacion = solicitud?.observacion;
    this.sistemaEnvioId = solicitud?.sistemaEnvioId;
    this.notificacionCorreo = solicitud?.notificacionCorreo;
    this.sistemaEnvio = solicitud?.sistemaEnvio;
    this.radicador = solicitud?.radicador;
    this.radicadorId = solicitud?.radicadorId;
    this.fechaCreacion = solicitud?.fechaCreacion;
    this.fechaRadicacion = solicitud?.fechaRadicacion;
    this.fechaFinDocs = solicitud?.fechaFinDocs;
    this.solicitanteId = solicitud?.solicitanteId;
    this.solicitante = solicitud?.solicitante;
    this.solicitanteTipoId = solicitud?.solicitanteTipoId;
    this.solicitanteTipo = solicitud?.solicitanteTipo;
    this.solicitud = solicitud?.solicitud;
    this.iconClass = solicitud?.iconClass;
    this.titleNameIcon = solicitud?.titleNameIcon;
    this.fechaReferencia = solicitud?.fechaReferencia;
    this.referenciaExterna = solicitud?.referenciaExterna;
  }

  get fechaBusqueda(): string {
    let _fecha = this.fechaCreacion?.toString().slice(0, 10);
    return _fecha;
  }
}
