import { createAction, props } from "@ngrx/store";
import { PredioModel } from "../models/predio.model";

export const setPredios = createAction(
  "[Predio Component] Set predios",
  props<{ predios: PredioModel[] }>()
);

export const unsetPredios = createAction("[Predio Component] Unset predios");

export const setPredioSelected = createAction(
  "[Predio Component] Set predio selected",
  props<{ predio: PredioModel }>()
);

export const unsetPredioSelected = createAction(
  "[Predio Component] Unset predio selected"
);
