import { createAction, props } from "@ngrx/store";
import { RoleModel } from "../models/roles.model";

export const setRolSelected = createAction(
  "[Rol Component] Set rol Selected",
  props<{ rol: RoleModel }>()
);

export const unsetRolSelected = createAction(
  "[Rol Component] unSet rol Selected"
);
