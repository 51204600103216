import { createAction, props } from "@ngrx/store";
import { ITramiteDocumentoTipo } from "../models/dtos/documento.requerido.model";

export const setDocumentoRequerido = createAction(
  "[DocumentoRequerido Component] Set documento requerido",
  props<{ documentoRequerido: ITramiteDocumentoTipo }>()
);

export const unsetDocumentoRequerido = createAction(
  "[DocumentoRequerido Component] Unset documento requerido"
);
