import { createAction, props } from "@ngrx/store";

export const setTrazaDetalleSelected = createAction(
  "[Traza Component] Set traza detalle Selected",
  props<{ detalle: string }>()
);

export const unsetTrazaDetalleSelected = createAction(
  "[Traza Component] unSet traza detalle Selected"
);
