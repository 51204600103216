import { TipoDocumentoModel } from "./tipo.documento";
export class SolicitanteModel {
  celular: string;
  correo: string;
  creadorId: number;
  documentoTipoId: number;
  documentoTipo: TipoDocumentoModel;
  fechaCreacion: string;
  id: number;
  nombres: string;
  nombre: string;
  nombre2: string;
  apellidos: string;
  apellido2: string;
  numeroDocumento: string;
  telefono: string;
  direccion: string;
  departamentoId: number;
  departamento: string;
  municipio: string;
  municipioId: number;
  ciudad: string;
  ciudadId: number;
  validadores: Validadores;
  editable: boolean;
  contacto: any;

  constructor(solicitante?: SolicitanteModel) {
    this.celular = solicitante?.celular;
    this.correo = solicitante?.correo;
    this.creadorId = solicitante?.creadorId;
    this.documentoTipoId = solicitante?.documentoTipoId;
    this.documentoTipo = solicitante?.documentoTipo;
    this.fechaCreacion = solicitante?.fechaCreacion;
    this.id = solicitante?.id;
    this.nombres = solicitante?.nombres;
    this.nombre = solicitante?.nombre;
    this.nombre2 = solicitante?.nombre2;
    this.apellidos = solicitante?.apellidos;
    this.apellido2 = solicitante?.apellido2;
    this.numeroDocumento = solicitante?.numeroDocumento;
    this.telefono = solicitante?.telefono;
    this.direccion = solicitante?.direccion;
    this.departamentoId = solicitante?.departamentoId;
    this.departamento = solicitante?.departamento;
    this.municipio = solicitante?.municipio;
    this.municipioId = solicitante?.municipioId;
    this.ciudad = solicitante?.ciudad;
    this.ciudadId = solicitante?.ciudadId;
    this.editable = solicitante?.editable;
  }

  get nombreCompleto(): string {
    return this.nombres + " " + this.apellidos;
  }
}

export class Validadores {
  celular: boolean;
  correo: boolean;
  nombres: boolean;
  nombre2: boolean;
  apellidos: boolean;
  apellido2: boolean;
  numeroDocumento: boolean;
  telefono: boolean;
  direccion: boolean;
  departamento: boolean;
  ciudad: boolean;
  tipoDocumento: boolean;
}
