import { createAction, props } from "@ngrx/store";
import { SolicitanteModel } from "../models/solicitante.model";

export const setSolicitante = createAction(
  "[Solicitante Component] Set solicitante",
  props<{ solicitante: SolicitanteModel }>()
);

export const unsetSolicitante = createAction(
  "[Solicitante Component] Unset solicitante"
);
