import { createReducer, on } from "@ngrx/store";
import { setPlantilla, unsetPlantilla } from "./plantilla-correo.actions";
import { ITramiteDocumentoTipo } from "../models/dtos/documento.requerido.model";
import {
  setDocumentoRequerido,
  unsetDocumentoRequerido,
} from "./documento-requerido.actions";

export interface State {
  documentoRequerido: ITramiteDocumentoTipo;
}

export const initialState: State = {
  documentoRequerido: null,
};

const _documentoRequeridoReducer = createReducer(
  initialState,
  on(setDocumentoRequerido, (state, { documentoRequerido }) => ({
    ...state,
    documentoRequerido: { ...documentoRequerido },
  })),
  on(unsetDocumentoRequerido, (state) => ({
    ...state,
    documentoRequerido: initialState.documentoRequerido,
  }))
);

export function documentoRequeridoReducer(state, action) {
  return _documentoRequeridoReducer(state, action);
}
