import { createReducer, on } from "@ngrx/store";
import { LogEsri } from "../models/log.esri.model";
import {
  setLogEsriSelected,
  unsetLogEsriSelected,
  setLogCatasiaSelected,
  unsetLogCatasiaSelected,
  setLogEsriModo,
  unsetLogEsriModo,
  ModosLogEsri,
} from "./sistema.actions";
import { LogCatasia } from "../models/log.catasia.model";

export interface State {
  logEsriSelected: LogEsri;
  logEsriModo: string;
  logCatasiaSelected: LogCatasia;
}

export const initialState: State = {
  logEsriSelected: undefined,
  logEsriModo: "",
  logCatasiaSelected: undefined,
};

const _sistemaReducer = createReducer(
  initialState,
  on(setLogEsriSelected, (state, { logEsri }) => ({
    ...state,
    logEsriSelected: { ...logEsri },
  })),
  on(unsetLogEsriSelected, (state) => ({
    ...state,
    logEsriSelected: initialState.logEsriSelected,
  })),
  on(setLogEsriModo, (state, { logEsriModo }) => ({
    ...state,
    logEsriModo: logEsriModo,
  })),
  on(unsetLogEsriModo, (state) => ({
    ...state,
    logEsriModo: initialState.logEsriModo,
  })),
  on(setLogCatasiaSelected, (state, { logCatasia }) => ({
    ...state,
    logCatasiaSelected: logCatasia,
  })),
  on(unsetLogCatasiaSelected, (state) => ({
    ...state,
    logCatasiaSelected: initialState.logCatasiaSelected,
  }))
);

export function sistemaReducer(state, action) {
  return _sistemaReducer(state, action);
}
