import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { environment } from "../../../environments/environment";
import { AppState } from "../../app.reducer";
import { UserInfoModel } from "../../models/user.info.model";
import { UserModel } from "../../models/user.model";
import { setLoggging, setUser } from "../../reducers/auth.actions";
import { SecurityService } from "../../services/security.service";
import { AppSettings } from "../../util/AppSettings";

@Component({
  selector: "app-login",
  templateUrl: "./app.login.component.html",
  providers: [MessageService, SecurityService],
  styleUrls: ["./app.login.component.scss"],
})
export class AppLoginComponent implements OnInit, OnDestroy {
  dark: boolean;

  logoLogin = environment.logoLogin;
  logofooterEsri = environment.logofooterEsri;
  logofooterEsriHeight = environment.logofooterEsriHeight;
  logofooterCatasIA = environment.logofooterCatasIA;
  logofooterCatasIAHeight = environment.logofooterCatasIAHeight;
  logoLoginHeight = environment.logoLoginHeight;
  loginText = environment.loginText;

  user: UserModel = new UserModel();

  loading = false;

  logging: boolean = false;

  rememberme: boolean;

  logginSubscription: Subscription;

  constructor(
    private service: MessageService,
    private securityService: SecurityService,
    private router: Router,
    private adminService: AdminService,
    private store: Store<AppState>
  ) {
    // router.events.subscribe((event: RouterEvent) => {
    //   if (event instanceof RouteConfigLoadStart) {
    //     console.log(event);
    //     this.loadingModules = true;
    //   }
    //   if (event instanceof RouteConfigLoadEnd) {
    //     console.log(event);
    //     this.loadingModules = false;
    //   }
    // });
  }

  ngOnInit() {
    this.logginSubscription = this.store
      .select("auth")
      .subscribe(({ logging }) => {
        this.logging = logging;
      });
    if (AppSettings.getToken()) {
      this.router.navigate(["/"]);
      return;
    }
    const fondos: string[] = environment["fondoLogin"];
    if (fondos) {
      for (let i = 0; i < fondos.length; i++) {
        new Image().src = fondos[i];
      }
      setTimeout(() => {
        const div = document.getElementById("loginBody");
        div.style.backgroundImage = "url('" + fondos[Math.floor(Math.random() * fondos.length)] + "')";
        div.style.backgroundSize = "cover";
      }, 1);
    }
  }

  ngOnDestroy(): void {
    this.logginSubscription?.unsubscribe();
  }

  redirectTo(token: string) {
    //Get user info and redirect
    this.adminService.getUserInfo(token).subscribe(
      (userInfo: UserInfoModel) => {
        this.store.dispatch(setUser({ user: userInfo }));
        this.router.navigate(["/"]);
      },
      (error) => {
        this.store.dispatch(setLoggging({ loggging: false }));
        this.service.clear();
        this.service.add({
          key: "tst",
          severity: "error",
          life: 10000,
          detail: "Error",
          summary: error.error.message,
        });
      }
    );
  }

  login() {
    this.loading = true;
    this.securityService.login(this.user).subscribe(
      (data: any) => {
        this.store.dispatch(setLoggging({ loggging: true }));
        // console.log("data: ", data);
        this.securityService.saveLogin(data.access_token, this.rememberme);
        this.redirectTo(data.access_token);
      },
      (err) => {
        this.loading = false;
        this.store.dispatch(setLoggging({ loggging: false }));
        console.log(err);
        this.service.clear();
        this.service.add({
          key: "tst",
          severity: "error",
          life: 10000,
          detail:
            err.error.error === "invalid_grant" ?
              "Usuario o contraseña incorrectos" :
              ((err.error.trace || err.error.error_description)?.indexOf('com.gm.catastro.api.service.LicenseService$InvalidLicense') >= 0 ?
                'Se encontraron problemas con la licencia del producto. Por favor contacte al administrador' :
                (err.status == 0 || err.error.message?.indexOf('Unable to find instance for ') >= 0 || err.error.message?.indexOf('503 Service Unavailable from ') >= 0 ?
                  'Los servicios están abajo en este momento. Por favor intente más tarde. Si el problema persiste, contacte con el administrador' :
                  err.error.message || err.message)),
          summary:
            err.error.error === "invalid_grant"
              ? "Error de autenticación"
              : err.error.error,
        });
      }
    );
  }
}
