import { Injectable } from "@angular/core";
import { AppSettings } from "../util/AppSettings";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "../models/user.model";
import { HttpHeaders } from "@angular/common/http";

import { environment } from "../../environments/environment";
import { CatasiaService } from "./catasia-service";
import { Store } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { unSetUser, setUser } from "../reducers/auth.actions";
import { UserInfoModel } from "../models/user.info.model";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SecurityService extends CatasiaService {
  storeSubscription: Subscription;
  user: UserInfoModel;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {
    super();
    this.storeSubscription = this.store.select("auth").subscribe(({ user }) => {
      this.user = user;
    });
  }

  login(user: UserModel) {
    let params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", user.email);
    params.append("password", user.password);

    let _headers = new HttpHeaders({
      "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
      Authorization:
        "Basic " + btoa(AppSettings.CLIENT_ID + ":" + AppSettings.CLIENT_PW),
    });
    return this.http.post(
      environment.API_LOGIN_URL + environment.host.ms_login.methods.token,
      params.toString(),
      {
        headers: _headers,
      }
    );
  }

  // getMenu() {
  //   return this.http.get(environment.API_URL + "menu", {
  //     headers: AppSettings.getHeader(),
  //   });
  // }

  getMenu() {
    return this.get(
      environment.host.ms_gateway.methods.moduloPermisos,
      this.http
    );
  }

  tieneModulo(codigo: string) {
    return this.get(
      'modulo/permiso/' + codigo,
      this.http
    );
  }

  getInfo() {
    return this.http.get(
      environment.API_URL + environment.host.ms_gateway.methods.getInfo,
      {
        headers: AppSettings.getHeader(),
      }
    );
  }

  saveLogin(token: string, remember: boolean) {
    // const storage = sessionStorage;
    sessionStorage.setItem("user.token", token);
  }

  logout() {
    this.post('usuario/logout', null, this.http).subscribe();
    this.store.dispatch(unSetUser());
    localStorage.clear();
    sessionStorage.clear();
  }

  isAuth() {
    if (!this.user) {
      this.getInfo().subscribe(
        (user: UserInfoModel) => {
          this.store.dispatch(setUser({ user }));
        },
        (err) => {
          console.log(err);
          this.logout();
          // location.href = "#/login";
          this.router.navigate(["/login"]);
        }
      );
    }
  }
}
