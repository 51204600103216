import { createAction, props } from "@ngrx/store";
import { PlantillaCorreo } from "../models/dtos/plantilla.correo.model";

export const setPlantilla = createAction(
  "[Plantilla Component] Set plantilla",
  props<{ plantilla: PlantillaCorreo }>()
);

export const unsetPlantilla = createAction(
  "[Plantilla Component] Unset plantilla"
);
