import { RadicarService } from "../services/radicar.service";
import { ArchivoTipoModel } from "../models/dtos/archivo.tipo.model";

export const tipoArchivosCompatible = async (
  radicarService: RadicarService
) => {
  let tipos = [];
  await radicarService
    .archivoTipos()
    .toPromise()
    .then((archivoTipos: ArchivoTipoModel[]) => {
      tipos = archivoTipos.map((tipo) => {
        return "." + tipo.nombre;
      });
    });
  return tipos;
};
