import { createAction, props } from "@ngrx/store";
import { PropietarioModel } from "../models/dtos/propietario.model";

export const setPropietarioSearch = createAction(
  "[Propietario Component] Set propietario search",
  props<{ propietarioSearch: PropietarioModel }>()
);

export const unsetPropietarioSearch = createAction(
  "[Propietario Component] Unset propietario search"
);
