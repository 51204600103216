export const getFileSizeUpload = () => {
  return 524288000; //500MB
};

export const getFileSizeUploadParseMB = () => {
  return getFileSizeUpload() / 1048576;
};

export const parseFileSize = (bytes: number): string => {
  const sizes = ['bytes', 'KB', 'MB', 'GB'];
  let currentSize = bytes;
  let sizeIndex = 0;
  while (currentSize > 1000) {
    currentSize = currentSize / 1000;
    sizeIndex += 1;
  }
  return `${currentSize.toFixed(2)} ${sizes[sizeIndex]}`
}
