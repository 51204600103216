import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppAccessdeniedComponent } from "./pages/accessdenied/app.accessdenied.component";
import { AppErrorComponent } from "./pages/error/app.error.component";
// catastro oficial
import { AppLoginComponent } from "./pages/login/app.login.component";
import { AppNotfoundComponent } from "./pages/notfound/app.notfound.component";
import { DevComponent } from "./pages/dev/dev.component";
import { AuthGuard } from "./guards/auth.guard";

export const routes: Routes = [
  { path: "dev", component: DevComponent },
  { path: "error", component: AppErrorComponent },
  { path: "accessdenied", component: AppAccessdeniedComponent },
  { path: "notfound", component: AppNotfoundComponent },
  { path: "login", component: AppLoginComponent },
  {
    path: "",
    loadChildren: () =>
      import("./components/main/app.main.module").then((m) => m.MainModule),
    canLoad: [AuthGuard],
  },
  { path: "**", redirectTo: "/notfound" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {
//   scrollPositionRestoration: "enabled",
// });
