import { createReducer, on } from "@ngrx/store";
import { setRolSelected, unsetRolSelected } from "./rol.actions";
import { RoleModel } from "../models/roles.model";

export interface State {
  rol: RoleModel;
}

export const initialState: State = {
  rol: null,
};

const _rolReducer = createReducer(
  initialState,
  on(setRolSelected, (state, { rol }) => ({
    ...state,
    rol: { ...rol },
  })),
  on(unsetRolSelected, (state) => ({
    ...state,
    rol: null,
  }))
);

export function rolReducer(state, action) {
  return _rolReducer(state, action);
}
