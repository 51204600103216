import { createAction, props } from "@ngrx/store";

export const setResolucionSelected = createAction(
  "[Resolucion Component] Set resolucion Selected",
  props<{ resolucion: any }>()
);

export const unsetResolucionSelected = createAction(
  "[Resolucion Component] unSet resolucion Selected"
);
