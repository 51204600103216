import { createReducer, on } from "@ngrx/store";
import { PropietarioModel } from "../models/dtos/propietario.model";
import {
  setPropietarioSearch,
  unsetPropietarioSearch,
} from "./propietario.actions";

export interface State {
  propietarioSearch: PropietarioModel;
}

export const initialState: State = {
  propietarioSearch: null,
};

const _propietarioReducer = createReducer(
  initialState,
  on(setPropietarioSearch, (state, { propietarioSearch }) => ({
    ...state,
    propietarioSearch: { ...propietarioSearch },
  })),
  on(unsetPropietarioSearch, (state) => ({
    ...state,
    propietarioSearch: initialState.propietarioSearch,
  }))
);

export function propietarioReducer(state, action) {
  return _propietarioReducer(state, action);
}
