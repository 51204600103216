import { createReducer, on } from "@ngrx/store";
import { UsuarioModel } from "../models/usuario.model";
import { setUsuario, unsetUsuario } from "./usuario.actions";

export interface State {
  usuario: UsuarioModel;
}

export const initialState: State = {
  usuario: null,
};

const _usuarioReducer = createReducer(
  initialState,
  on(setUsuario, (state, { usuario }) => ({
    ...state,
    usuario: { ...usuario },
  })),
  on(unsetUsuario, (state) => ({
    ...state,
    usuario: initialState.usuario,
  }))
);

export function usuarioReducer(state, action) {
  return _usuarioReducer(state, action);
}
