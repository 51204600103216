import { createReducer, on } from "@ngrx/store";
import {
  setTrazaDetalleSelected,
  unsetTrazaDetalleSelected,
} from "./traza.actions";

export interface State {
  detalle: string;
}

export const initialState: State = {
  detalle: null,
};

const _trazaReducer = createReducer(
  initialState,
  on(setTrazaDetalleSelected, (state, { detalle }) => ({
    ...state,
    detalle,
  })),
  on(unsetTrazaDetalleSelected, (state) => ({
    ...state,
    detalle: initialState.detalle,
  }))
);

export function trazaReducer(state, action) {
  return _trazaReducer(state, action);
}
