import { createAction, props } from "@ngrx/store";
import { ParametrosModel } from "../models/parametros.model";

export const setParametro = createAction(
  "[Parametro Component] Set parametro",
  props<{ parametro: ParametrosModel }>()
);

export const unsetParametro = createAction(
  "[Parametro Component] Unset parametro"
);
