import { createReducer, on } from "@ngrx/store";
import { PredioModel } from "../models/predio.model";
import {
  setPredios,
  unsetPredios,
  setPredioSelected,
  unsetPredioSelected,
} from "./predios.actions";

export interface State {
  predioSelected: PredioModel;
  predios: PredioModel[];
}

export const initialState: State = {
  predioSelected: null,
  predios: [],
};

const _predioReducer = createReducer(
  initialState,
  on(setPredios, (state, { predios }) => ({
    ...state,
    predios: [...predios],
  })),
  on(unsetPredios, (state) => ({
    ...state,
    predios: initialState.predios,
  })),
  on(setPredioSelected, (state, { predio }) => ({
    ...state,
    predioSelected: { ...predio },
  })),
  on(unsetPredioSelected, (state) => ({
    ...state,
    predioSelected: initialState.predioSelected,
  }))
);

export function predioReducer(state, action) {
  return _predioReducer(state, action);
}
