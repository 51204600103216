import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppSettings } from "../util/AppSettings";

export class CatasiaService {
  get(url: string, http: HttpClient) {
    return http.get(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
    });
  }

  getText(url: string, http: HttpClient) {
    return http.get(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
      responseType: 'text'
    });
  }

  getBlob(url: string, http: HttpClient) {
    return http.get(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
      responseType: 'blob'
    });
  }

  post(url: string, body: any, http: HttpClient, headers: any = {}) {
    return http.post(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(headers),
    });
  }

  postForm(url: string, body: any, http: HttpClient) {
    return this.post(url, body, http, {
      "Content-Type": "application/x-www-form-urlencoded"
    });
  }

  postReportProgress(url: string, body: any, http: HttpClient) {
    return http.post(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(),
      reportProgress: true,
      observe: "events",
    });
  }

  postBlob(url: string, body: any, http: HttpClient) {
    return http.post(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(),
      responseType: "blob",
    });
  }

  put(url: string, body: any, http: HttpClient) {
    return http.put(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(),
    });
  }
  delete(url: string, http: HttpClient) {
    return http.delete(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
    });
  }
}
