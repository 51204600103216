import { createReducer, on } from "@ngrx/store";
import { setUser, unSetUser, setLoggging, unSetLogging } from "./auth.actions";
import { UserInfoModel } from "../models/user.info.model";

export interface State {
  user: UserInfoModel;
  logging: boolean;
}

export const initialState: State = {
  user: null,
  logging: false,
};

const _authReducer = createReducer(
  initialState,
  on(setUser, (state, { user }) => ({ ...state, user: { ...user } })),
  on(unSetUser, (state) => ({ ...state, user: null })),
  on(setLoggging, (state, { loggging }) => ({ ...state, logging: loggging })),
  on(unSetLogging, (state) => ({ ...state, logging: false }))
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
