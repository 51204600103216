import { createReducer, on } from "@ngrx/store";
import {
  setResolucionSelected,
  unsetResolucionSelected,
} from "./resolucion.actions";

export interface State {
  resolucion: any;
}

export const initialState: State = {
  resolucion: null,
};

const _resolucionReducer = createReducer(
  initialState,
  on(setResolucionSelected, (state, { resolucion }) => ({
    ...state,
    resolucion: { ...resolucion },
  })),
  on(unsetResolucionSelected, (state) => ({
    ...state,
    resolucion: null,
  }))
);

export function resolucionReducer(state, action) {
  return _resolucionReducer(state, action);
}
