// const URL = "http://gairadb.com:9090"; //barranquilla
// const URL = "http://207.244.227.72:9090"; //barranquilla
// const URL = "http://gairadb.com:11090"; //demo
// const URL = "http://207.244.227.72:11090"; //demo
//const URL = 'http://localhost:8090';

//export const API_GEONATIVO = "https://geonativo.catasia.co/api/catastroregistro/v1/";
export const API_GEONATIVO = "/api/catastroregistro/v1/";
export const API_REPORT = "/api/report/";

export const globalEnvironment = {
  ms_gateway: {
    methods: {
      menu: "menu",
      users: "usuario",
      usersGeo: "usuario/usuariosGeos",
      getInfo: "usuario/getInfo",
      usuarioCambiarClave: "usuario/cambiarClave",
      usuarioFirma: "usuario/firma/",
      usuarioById: "usuario/findById/",
      usuarioByEmail: "usuario/findByEmail/",
      usuarioByModulo: "usuario/findByModulo/",
      usuarioEstados: "usuarioEstado",
      getEjecutorFase: "usuario/getEjecutorFase/",
      roles: "rol",
      rolModulo: "rolModulo",
      rolModuloMenu: "rolModulo/menu",
      documentTypes: "documentTypes",
      documentoTipo: "documentoTipo",
      country: "country",
      departamento: "departamento",
      ciudad: "ciudad",
      ciudadByDepartamento: "ciudad/findByDepartamentoId/",
      entityType: "entityType",
      timezone: "timezone",
      entity: "entity",
      person: "person",
      client: "client",
      solicitud: "solicitud",
      solicitudFiltrar: "solicitud/filtrar",
      solicitudSinTramites: "solicitud/solicitudesSinTramite",
      solicitudFindByNumero: "solicitud/findByNumeroSolicitud/",
      solicitudFindTramitesBySolicitudId:
        "solicitud/findTramitesBySolicitudId/",
      solicitudTipo: "solicitudTipo",
      generarConstanciaSolicitud: "solicitud/constanciaSolicitud/",
      generarConstanciaRadicacion: "solicitud/constanciaRadicacion/",
      generarConstanciaRecurso: "tramite/constanciaRecurso/",
      enviarConstanciaRecurso: "tramite/emailConstanciaRecurso/",
      formaPeticion: "formaPeticion",
      predioFindByFilters: "predio/findByFilters",
      predioBuscar: "predio/buscarPredio/",
      predioByAnterior: "predio/buscarPrediosByAnterior",
      predioByDireccion: "predio/buscarPrediosByDireccion",
      predioByDocumento: "predio/buscarPrediosByDocumento",
      predioByPredial: "predio/buscarPrediosByPredial",
      predioByfmi: "predio/buscarPrediosByFmi",
      solicitante: "solicitante",
      solicitanteFiltrar: "solicitante/filtrar",
      solicitanteTipos: "solicitanteTipo",
      solicitanteBytramite: "solicitante/findByTramite/",
      tramite: "tramite",
      tramiteFiltrar: "tramite/filtrar",
      tramiteFiltrarIds: "tramite/filtrarIds",
      tramiteRadicar: "tramite/radicar/",
      tramiteById: "tramite/",
      archivo: "archivo",
      archivoTipo: "archivoTipo",
      archivoDescargar: "archivo/descargar/",
      archivoGuardar: "archivo/guardar",
      archivoGuardarPublico: "archivo/guardarPublico",
      escritorioTramites: "escritorio/tramites",
      escritorioTramitesByModulo: "escritorio/tramitesByModulo/",
      escritorioTramitesByFase: "escritorio/tramitesByFase/",
      escritorioValidarRechazoRecurso: "escritorio/validarRechazoRecurso",
      escritorioRecursoValidacion: "escritorio/recursoValidacion",
      escritorioEtapaProbatoria: "escritorio/autoApertura",
      devolverEtapaProbatoria: "escritorio/devolverEtapaProbatoria",
      devolverFirmaAutoApertura: "escritorio/devolverFirmaAutoApertura",
      firmaAutoApertura: "escritorio/firmaAutoApertura",
      aprobarAutoApertura: "escritorio/aprobarAutoApertura",
      iniciarEtapaProbatoria: "escritorio/iniciarEtapaProbatoria",
      tramiteProcedente: "escritorio/procedente",
      tramiteAsignar: "escritorio/asignar",
      tramiteAsignarMultiple: "escritorio/asignarTramites",
      tramiteDevolver: "escritorio/devolver",
      tramiteDevolverRadicado: "escritorio/devolverRadicado",
      tramiteRechazoEjecutor: "escritorio/rechazoEjecutor",
      tramiteAceptarAsignacion: "escritorio/aceptarAsignacion",
      tramiteRechazar: "escritorio/rechazar",
      tramiteReasignar: "escritorio/reasignar",
      tramiteReasignarMasivo: "escritorio/reasignarTramites",
      tramiteValidacionJuridica: "escritorio/validacionJuridica",
      tramiteValidacionTecnica: "escritorio/validacionTecnica",
      tramiteValidado: "escritorio/validado",
      tramiteAprobar: "escritorio/aprobar",
      tramiteCancelar: "escritorio/cancelar",
      tramiteRestaurar: "escritorio/restaurar",
      tramiteCambiarEstado: "escritorio/cambiarEstado",
      tramiteFinalizarDigitalizacion: "escritorio/finalizarDigitalizacion",
      tramiteAprobarRecurso: "escritorio/aprobarRecurso",
      tramiteRechazarRecurso: "escritorio/rechazarRecurso",
      tramiteDespacho: "escritorio/despacho",
      fechaEtapaProbatoria: "escritorio/getFechaEtapaProbatoria",
      escritorioAgregarObservacion: "escritorio/agregarObservacion",
      escritorioAsignarme: "escritorio/asignarme",
      escritorioDesasignarme: "escritorio/desasignarme",
      escritorioRadicado: "escritorio/radicado",
      tramiteCompatible: "tramiteTipo/compatibles/",
      tramitePendientes: "tramite/pendientes/",
      tramitesVentanilla: "tramiteTipo/ventanilla",
      tramiteTipoCompatible: "tramiteTipoCompatible",
      tramitesTipos: "tramiteTipo",
      tramitesSincronizar: "escritorio/sincronizarTramites",
      tramiteSincronizar: "escritorio/sincronizarTramite/",
      fases: "fase",
      estados: "fase/estadosByTramite/",
      tramiteFase: "tramiteFase/findByTramiteId/",
      tramiteFindByPredio: "tramite/findByPredio/",
      tramiteFaseResponsable: "tramiteFaseResponsable/findByTramiteId/",
      escritorioEjecutores: "usuario/ejecutores",
      supervisoresByOficinaId: "usuario/supervisores",
      caracteristicaModificaciones: "modificacionCaracteristica",
      caracteristicasPrediosComplementacion:
        "modificacionCaracteristica/complementaciones/predio",
      caracteristicasPrediosRestificacion:
        "modificacionCaracteristica/rectificaciones/predio",
      caracteristicasPropietariosComplementacion:
        "modificacionCaracteristica/complementaciones/propietario",
      caracteristicasPropietariosRectificacion:
        "modificacionCaracteristica/rectificaciones/propietario",
      tramitePredio: "tramitePredio",
      cambiaronPrediosTramite: "tramitePredio/cambiaronPredios/",
      propietarioByPredio: "predioPropietario/byPredio",
      propietario: "predioPropietario",
      propietarioAntiguos: "predioPropietario/historial/",
      propietariosIds: "tramitePredio/findByTramite",
      propietarioByTramitePredio: "predioPropietario/findByTramitePredioId",
      tramiteCaracteristica: "tramiteModificacionCaracteristica",
      tramiteCaracteristicarByTramite:
        "tramiteModificacionCaracteristica/findByTramiteId/",
      tramiteCaracteristicasDeleteByTramite:
        "tramiteModificacionCaracteristica/deleteByTramite/",
      tipotitulo: "tipoTitulo",
      modoAdquisicion: "modoAdquisicion",
      infoAvaluos: "general/decreto",
      clasificacionTramite: "clasificacionTramite",
      derechoPropiedad: "derechoPropiedad",
      derechoPropiedadByPredio: "derechoPropiedad/findByPredio/",
      derechoPropiedadByTramitePredio:
        "derechoPropiedad/findByTramitePredioId/",
      saveFechaInscripcionCatastral: "derechoPropiedad/saveFechaInscripcionCatastralTramite",
      getFechaInscripcionCatastral: "derechoPropiedad/getFechaInscripcionCatastralTramite/",
      tipoPersona: "tipoPersona",
      tramiteinfoResolucion: "tramite/infoResolucion/",
      historicoTramite: "tramite/historico/",
      tramiteResolucionCargar: "tramiteResolucion/resolucion/",
      tramiteResolucionGenerar: "tramiteResolucion/generar/",
      tramiteResolucionDescargar: "tramiteResolucion/ultima/",
      tramiteResolucionHistorial: "tramiteResolucion/findByTramiteId/",
      tramiteResolucionGenerarRechazoRecurso: "tramiteResolucion/generarRechazoRecurso/",
      tramiteResolucionFirmarRechazo: "tramiteResolucion/aprobarRechazoRecurso/",
      tramiteResolucionAnaloga: "tramiteResolucion/analoga/",
      tramiteResolucionSize: "tramiteResolucion/size",
      verPlantillaInformeTecnico: "plantilla/informeTecnico",
      propietarioPredio: "predioPropietario",
      buscarPropietarios: "predioPropietario/buscarPropietarios",
      activarMismosPropietarios: "tramite/activarMismosPropietarios/",
      desactivarMismoPropietarios: "tramite/desactivarMismosPropietarios/",
      tramiteNotificado: "tramiteNotificado",
      tramiteNotificadoGenerarCitacion: "tramiteNotificado/generarCitacion/",
      tramiteNotificadoEmailGenerico: "tramiteNotificado/email/",
      tramiteNotificadoEmailCitacion: "tramiteNotificado/emailCitacion/",
      tramiteNotificadoEmailResolucion: "tramiteNotificado/emailResolucion/",
      tramiteNotificadoTextoCorreoCitacion:
        "tramiteNotificado/textoCorreoCitacion/",
      tramiteNotificadoTextoCorreoComunicacion:
        "tramiteNotificado/textoCorreoComunicacion/",
      tramiteNotificadoTextoCorreoRechazo:
        "tramiteNotificado/textoCorreoRechazo/",
      tramiteNotificadotextoCorreoRechazoRecurso:
        "tramiteNotificado/textoCorreoRechazoRecurso/",
      tramiteNotificadoTextoCorreoArchivo:
        "tramiteNotificado/textoCorreoArchivo/",
      plantillaEmailComunicar: "plantillaEmail/comunicar",
      plantillaEmailtextoByTramite: "plantillaEmail/textoByTramite/",
      tramiteNotificadoByTramite: "tramiteNotificado/findByTramiteId/",
      tramiteNotificacionTraza: "traza/trazaSecretaria/",
      tramiteNotificadoEstado: "tramiteNotificado/cambiarEstado/",
      estadosNotificacion: "fase/findByTramiteEstadoId/",
      notificacionArchivo: "notificacionArchivo",
      tipoRecurso: "tipoRecurso",
      tipoRecursoByTramite: "tipoRecurso/findByTramiteId/",
      tiposRecursoProcedenByTramite: "tipoRecurso/proceden/",
      tramiteTipoRecurso: "tipoRecurso/tramite/",
      plantilla: "plantilla",
      plantillaDescargar: "plantilla/descargar/",
      plantillaCambiar: "plantilla/cambiarPlantilla/",
      plantillafindByTramiteId: "plantilla/findByTramiteId/",
      moduloPermisos: "modulo/permisos",
      cintasTramite: "cintas/tramite",
      cintasResoluciones: "cintas/xresoluciones",
      cintasFechas: "cintas/xfechas",
      cinstasMultiFechas: "cintas/xarreglofechas",
      productosPredio: "r1r2/xpredio",
      productosManzana: "r1r2/xmanzana",
      productosMunicipio: "r1r2/xmunicipio",
      productosSector: "r1r2/xsector",
      productosZona: "r1r2/xzona",
      productosDocumento: "r1r2/xdocumento",
      productosRangoPredio: "r1r2/xpredioRango",
      productosListaPredio: "r1r2/xpredioLista",
      productosRangoManzana: "r1r2/xmanzanaRango",
      productosListaManzana: "r1r2/xmanzanaLista",
      productosDescarga: "r1r2/download",
      tipoProductos: "tipoProductoR1R2",
      tipoConsulta: "tipoConsultaR1R2",
      tipoPedido: "tipoPedidoR1R2",
      productoR1R2: "productoR1R2",
      estadosProductoR1R2: "estadoR1R2",
      tramiteHistorico: "tramiteHistoricoBaq",
      tramiteHistoricoEstadosTramite: "tramiteHistoricoBaq/estadosTramite",
      tramiteHistoricoTipoSolicitud: "tramiteHistoricoBaq/tipoSolicitud",
      tramiteHistoricoTipoTramite: "tramiteHistoricoBaq/tipoTramite",
      parametro: "parametro",
      consecutivo: "consecutivo",
      consecutivoCambiar: "consecutivo/cambiar/",
      trazaFiltrar: "traza/filtrar",
      tipoTraza: "tipoTraza",
      trazaByReferenciaId: " traza/trazaByReferenciaId/",
      tramiteFiltrarCSV: "tramite/filtrar/csv",
      tramiteFiltrarPDF: "tramite/filtrar/pdf",
      tramiteFiltrarXLSX: "tramite/filtrar/xlsx",
      tramiteDocumento: "tramiteDocumentoTipo",
      plantillasCorreo: "plantillaEmail",
      tramiteMarcarCampo: "tramite/marcarCampo/",
      tramiteDesmarcarCampo: "tramite/desmarcarCampo/",
      generalSumarDias: "general/sumarDias/",
      recursosInterpuestos: "tramite/recursos/",
      finesRegistrales: "tramite/finesRegistrales/",
      actualizarDetalles: "tramite/actualizarDetalles/"
    },
    documentos: {
      documentoBorrador: {
        documentoBorradorByDocumentoId: "documentoBorrador/findByDocumentoId/",
      },
      documentoFiltrar: "documento/filtrar",
      documentoAnular: "documento/anular/",
      documentoDesanular: "documento/desanular/",
      documentoGenerarBorrador: "documento/generar/",
      documentoRadicar: "documento/radicar/",
      documentoDescargarUltimo: "documento/ultimo/",
      documentoDescargarUltimoPDF: "documento/ultimo/pdf/",
      //documentoFirmar: "documento/firmar/",
      documentosByTramiteId: "documento/findByTramiteId/",
      documentoByTramiteIdTipoDocumento: "documento/get/",
      documentoInternoGuardar: "documento/guardar/",
      documentosHistorial: "documentoBorrador/findByDocumentoId/",
      enviarDocumento: "documento/email/",
      firmarDocumento: "documento/firmar/",
      tipoDocumental: "tipoDocumental",
      documentoRecursoVerbal: "documento/recursoVerbal/",
      documentoTraza: "documento/traza/",
      documentoExpedienteSize: "documento/expedientes/size",
      snrImagenesPlano: "documento/snr/imagenesPlano/",
      snrDescartarImagen: "documento/snr/descartarImagen/",
      snrDescartarImagenes: "documento/snr/descartarImagenes/",
      snrGenerarBorradores: "documento/snr/generar/"
    },
    sistema: {
      actualesEsri: "peticionEsri/actuales",
      cancelarEsri: "peticionEsri/cancelar/",
      erroresEsti: "peticionEsri/filtrar",
      erroresCatasia: "utils/errors",
      clearCacheJPA: "utils/clearCacheJPA",
      clearCachePredios: "utils/clearCachePredios",
      clearErrors: "utils/clearErrors",
      hilosActuales: "utils/hilosActuales",
      hilosDemorados: "utils/hilosDemorados",
    },
    geonativo: {
      cargaArchivos: "cargaarchivos",
      cargaArchivosById: "cargaarchivos/",
      cargaArchivosByEstado: "cargaarchivos/estado/"
    },
    report: {
      documentoAnalogo: {
        fichaPredial: "documento-analogo/ficha-predial/"
      }
    }
  },
  ms_login: {
    methods: {
      token: "token",
    },
  },
};
