import { SolicitanteModel } from "./../models/solicitante.model";
import { createReducer, on } from "@ngrx/store";
import { setSolicitante, unsetSolicitante } from "./solicitante.actions";

export interface State {
  solicitante: SolicitanteModel;
}

export const initialState: State = {
  solicitante: null,
};

const _solicitanteReducer = createReducer(
  initialState,
  on(setSolicitante, (state, { solicitante }) => ({
    ...state,
    solicitante: { ...solicitante },
  })),
  on(unsetSolicitante, (state) => ({
    ...state,
    solicitante: null,
  }))
);

export function solicitanteReducer(state, action) {
  return _solicitanteReducer(state, action);
}
