import { ActionReducerMap } from "@ngrx/store";
import * as tramite from "./reducers/tramite.reducer";
import * as sistema from "./reducers/sistema.reducer";
import * as solicitud from "./reducers/solicitud.reducer";
import * as usuario from "./reducers/usuario.reducer";
import * as parametro from "./reducers/parametro.reducer";
import * as resolucion from "./reducers/resolucion.reducer";
import * as consecutivo from "./reducers/consecutivo.reducer";
import * as traza from "./reducers/traza.reducer";
import * as propietario from "./reducers/propietario.reducer";
import * as solicitante from "./reducers/solicitante.reducer";
import * as rol from "./reducers/rol.reducer";
import * as auth from "./reducers/auth.reducer";
import * as predio from "./reducers/predios.reducer";
import * as rendimiento from "./reducers/rendimiento.reducer";
import * as plantillaCorreos from "./reducers/plantilla-correo.reducer";
import * as documentoRequeridos from "./reducers/documento-requerido.reducer";
export interface AppState {
  tramite: tramite.State;
  sistema: sistema.State;
  solicitud: solicitud.State;
  usuario: usuario.State;
  parametro: parametro.State;
  resolucion: resolucion.State;
  consecutivo: consecutivo.State;
  traza: traza.State;
  propietario: propietario.State;
  solicitante: solicitante.State;
  rol: rol.State;
  auth: auth.State;
  predio: predio.State;
  rendimiento: rendimiento.State;
  plantillaCorreos: plantillaCorreos.State;
  documentoRequeridos: documentoRequeridos.State;
}
export const appReducer: ActionReducerMap<AppState> = {
  tramite: tramite.tramiteReducer,
  sistema: sistema.sistemaReducer,
  solicitud: solicitud.solicitudReducer,
  usuario: usuario.usuarioReducer,
  parametro: parametro.parametroReducer,
  resolucion: resolucion.resolucionReducer,
  consecutivo: consecutivo.consecutivoReducer,
  traza: traza.trazaReducer,
  propietario: propietario.propietarioReducer,
  solicitante: solicitante.solicitanteReducer,
  rol: rol.rolReducer,
  auth: auth.authReducer,
  predio: predio.predioReducer,
  rendimiento: rendimiento.rendimientoReducer,
  plantillaCorreos: plantillaCorreos.plantillaReducer,
  documentoRequeridos: documentoRequeridos.documentoRequeridoReducer,
};
