import { HttpHeaders } from "@angular/common/http";

export class AppSettings {
  public static CLIENT_ID = "frontendApp";
  public static CLIENT_PW = "fr4nt2020";
  public static BEARER = "Bearer ";

  public static formatDate = "dd/MM/yyyy";
  public static locale = "es";

  public static getHeader(headers: any = {}) {
    const token = AppSettings.getToken();
    return new HttpHeaders({
      ...headers,
      Authorization: "Bearer " + token,
    });
  }

  public static getHeaderByToken(token: string) {
    console.log("getHeaderByToken:" + token);
    return new HttpHeaders({
      Authorization: this.BEARER + token,
    });
  }

  public static getBearerHeader() {
    const token = AppSettings.getToken();
    return new HttpHeaders({
      Authorization: this.BEARER + token,
    });
  }

  public static getToken() {
    return sessionStorage.getItem("user.token");
  }

  /*public static getName() {
    return sessionStorage.getItem('user.name') || localStorage.getItem('user.name');
  }*/

  public static hashCode(s: string) {
    let h = 0;
    const l = s.length;
    let i = 0;
    if (l > 0) {
      while (i < l) {
        h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
      }
    }

    return h;
  }
}
