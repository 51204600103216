import { createReducer, on } from "@ngrx/store";
import { ThreadModel } from "../models/thread.model";
import { setThread, unsetThread } from "./rendimiento.actions";

export interface State {
  threadSelected: ThreadModel;
}

export const initialState: State = {
  threadSelected: null,
};

const _rendimientoReducer = createReducer(
  initialState,
  on(setThread, (state, { threadSelected }) => ({
    ...state,
    threadSelected: { ...threadSelected },
  })),
  on(unsetThread, (state) => ({
    ...state,
    threadSelected: initialState.threadSelected,
  }))
);

export function rendimientoReducer(state, action) {
  return _rendimientoReducer(state, action);
}
