import { createAction, props } from "@ngrx/store";
import { ConsecutivoModel } from "../models/consecutivo.model";

export const setConsecutivo = createAction(
  "[Consecutivo Component] Set consecutivo",
  props<{ consecutivo: ConsecutivoModel }>()
);

export const unseConsecutivo = createAction(
  "[Consecutivo Component] Unset consecutivo"
);
