import { createReducer, on } from "@ngrx/store";
import { setSolicitud, unsetSolicitud } from "./solicitud.actions";
import { SolicitudModel } from "../models/solicitud";

export interface State {
  solicitud: SolicitudModel;
}

export const initialState: State = {
  solicitud: null,
};

const _solicitudReducer = createReducer(
  initialState,
  on(setSolicitud, (state, { solicitud }) => ({
    ...state,
    solicitud: { ...solicitud },
  })),
  on(unsetSolicitud, (state) => ({
    ...state,
    solicitud: null,
  }))
);

export function solicitudReducer(state, action) {
  return _solicitudReducer(state, action);
}
