import { createReducer, on } from "@ngrx/store";
import {
  setTramite,
  unsetTramite,
  setTramiteTipoCurrent,
} from "./tramite.actions";
import { TramiteModel } from "../models/tramite.model";
import { TipoTramiteModel } from "../models/tipoTramite.model";
import { unsetTramiteTipoCurrent } from "./tramite.actions";

export interface State {
  tramite: TramiteModel;
  tramiteTipoCurrent: TipoTramiteModel;
}

export const initialState: State = {
  tramite: new TramiteModel(),
  tramiteTipoCurrent: null,
};

const _tramiteReducer = createReducer(
  initialState,
  on(setTramite, (state, { tramite }) => ({
    ...state,
    tramite: { ...tramite },
  })),
  on(unsetTramite, (state) => ({ ...state, tramite: initialState.tramite })),
  on(setTramiteTipoCurrent, (state, { tramiteTipo }) => ({
    ...state,
    tramiteTipoCurrent: { ...tramiteTipo },
  })),
  on(unsetTramiteTipoCurrent, (state) => ({
    ...state,
    tramiteTipoCurrent: initialState.tramiteTipoCurrent,
  }))
);

export function tramiteReducer(state, action) {
  return _tramiteReducer(state, action);
}
