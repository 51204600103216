import { labelsCatasia } from "../app/resource/labels";
import { globalEnvironment } from "./globalEnvironment";

// const baseURL = "https://c.catasia.co";
const baseURL = "";

export const environment = {
  environment: "capacitacion",
  production: false,
  name: "capacitacion",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],

  //GUI
  theme: "catasia",
  logoLogin: "assets/layout/images/catasia/catasia_vertical.png",
  loginText: null,
  logoTopBar: "assets/layout/images/catasia/catasia_horizontal.png",
  textTopBar: null,
  logofooterEsri: null,
  logofooterCatasIA: null,
  logofooterEsriHeight: null,
  logofooterCatasIAHeight: null,
  logoLoginHeight: "180px",
  fondoLogin: [
    "assets/layout/images/catasia/fondos/1.png",
    "assets/layout/images/catasia/fondos/2.png",
    "assets/layout/images/catasia/fondos/3.png",
    "assets/layout/images/catasia/fondos/4.png",
    "assets/layout/images/catasia/fondos/5.png",
    "assets/layout/images/catasia/fondos/6.png",
    "assets/layout/images/catasia/fondos/7.png",
    "assets/layout/images/catasia/fondos/8.png",
    "assets/layout/images/catasia/fondos/9.png",
    "assets/layout/images/catasia/fondos/10.png",
    "assets/layout/images/catasia/fondos/11.png",
    "assets/layout/images/catasia/fondos/12.jpg",
    "assets/layout/images/catasia/blob_bg.svg"
  ],
  desarrollo: true
};
