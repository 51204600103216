import { createAction, props } from "@ngrx/store";
import { TramiteModel } from "../models/tramite.model";
import { TipoTramiteModel } from "../models/tipoTramite.model";

export const setTramite = createAction(
  "[Tramite Component] Set tramite",
  props<{ tramite: TramiteModel }>()
);

export const unsetTramite = createAction("[Tramite Component] Unset tramite");

export const setTramiteTipoCurrent = createAction(
  "[Tramite Component] Set tramite tipo",
  props<{ tramiteTipo: TipoTramiteModel }>()
);

export const unsetTramiteTipoCurrent = createAction(
  "[Tramite Component] Unset tramite tipo"
);
