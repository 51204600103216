import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppLoginComponent } from "./app.login.component";
import { CheckboxModule } from "primeng/checkbox";
import { ToastModule } from "primeng/toast";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessageService } from "primeng/api";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";
import { ButtonModule } from "primeng/button";
import { LoaderComponent } from "../loader/loader.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";

@NgModule({
  declarations: [AppLoginComponent, LoaderComponent],
  imports: [
    CommonModule,
    CheckboxModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService],
  exports: [
    LoaderComponent,
    ProgressSpinnerModule
  ],
})
export class LoginModule {}
