export const labelsCatasia = {
  "version1.2": {
    moduloEjecucion: {
      tituloComponentePropietarios: "Propietarios y/o poseedores",
      recargarPropietarios: "Recargar propietarios",
      agregarPropietario: "Agregar propietario y/o poseedor",
      verPropietariosAnteriores: "Ver propietarios anteriores",
      verPropietario: "Ver propietario",
      modificarPropietario: "Modificar propietario y/o poseedor",
      eliminarPropietario: "Eliminar propietario y/o poseedor",
      mismoPropietariosDestino: "Mismos propietarios destinos",
      diferentesPropietariosDestino: "Diferentes propietarios destinos",
    },
    propietario: {
      labelPrincipal: "Propietario",
      tipodocumento: "Tipo Documento",
      nrodocumento: "No. Identificación",
      porcentajeparticipacion: "Porcentaje Participación",
      porcentajesparticipacion: "porcentajes de participación",
      mPorcentajeparticipacion: "porcentaje de participación"
    },
    predio: {
      labelPrincipal: "Predio",
      predios: "Predios",
      mPredios: "predios",
      predio: "predio",
      n_predial: "Número predial",
      fmi: "Matrícula inmobiliaria",
      destinoeconomico: "Destinación económica",
      tipopredio: "Tipo predio",
      condicion: "Condición",
      areaconstruccion: "Área construcción",
      areaterreno: "Área terreno",
      vigencia: "Vigencia",
      avaluo: "Avalúo catastral",
      coefcopropiedad: "Coeficiente de copropiedad",
      nupre: "NUPRE",
      zona: "Zona"
    },
    derechoPropiedad: {
      entidadEmisora: "Ente emisor",
      numeroTitulo: "Número del título",
      ciudad: "Municipio",
      departamento: "Departamento",
      tipoTitulo: "Tipo de título",
      fechaTitulo: "Fecha del título",
      fechaRegistro: "Fecha registro",
    }
  },
  "version2.0": {
    moduloEjecucion: {
      tituloComponentePropietarios: "Interesados",
      recargarPropietarios: "Recargar interesados",
      agregarPropietario: "Agregar interesado",
      verPropietariosAnteriores: "Ver interesados anteriores",
      verPropietario: "Ver interesado",
      modificarPropietario: "Modificar interesado",
      eliminarPropietario: "Eliminar interesado",
      mismoPropietariosDestino: "Mismos interesados destinos",
      diferentesPropietariosDestino: "Diferentes interesados destinos",
    },
    propietario: {
      labelPrincipal: "Interesado",
      tipodocumento: "Tipo documento",
      nrodocumento: "Número documento",
      porcentajeparticipacion: "Porcentaje participación",
      porcentajesparticipacion: "porcentajes de participación",
      mPorcentajeparticipacion: "porcentaje de participación"
    },
    predio: {
      labelPrincipal: "Predio",
      predios: "Predios",
      mPredios: "predios",
      predio: "predio",
      n_predial: "Número predial",
      fmi: "Matrícula inmobiliaria",
      destinoeconomico: "Destinación económica",
      tipopredio: "Tipo de Predio",
      condicion: "Condición",
      areaconstruccion: "Área construcción",
      areaterreno: "Área terreno",
      vigencia: "Vigencia",
      avaluo: "Avalúo catastral",
      coefcopropiedad: "Coeficiente de copropiedad",
      nupre: "NUPRE",
      zona: "Zona"
    },
    derechoPropiedad: {
      entidadEmisora: "Ente emisor",
      numeroTitulo: "Número de fuente",
      ciudad: "Municipio",
      departamento: "Departamento",
      tipoTitulo: "Tipo de documento fuente administrativa",
      fechaTitulo: "Fecha documento fuente",
      fechaRegistro: "SNR fecha datos",
    }
  },
  quito: {
    moduloEjecucion: {
      tituloComponentePropietarios: "Interesados",
      recargarPropietarios: "Recargar interesados",
      agregarPropietario: "Agregar interesado",
      verPropietariosAnteriores: "Ver interesados anteriores",
      verPropietario: "Ver interesado",
      modificarPropietario: "Modificar interesado",
      eliminarPropietario: "Eliminar interesado",
      mismoPropietariosDestino: "Mismos interesados destinos",
      diferentesPropietariosDestino: "Diferentes interesados destinos",
    },
    propietario: {
      labelPrincipal: "Interesado",
      tipodocumento: "Tipo documento",
      nrodocumento: "Número documento",
      porcentajeparticipacion: "Porcentaje de acciones",
      porcentajesparticipacion: "porcentajes de acciones",
      mPorcentajeparticipacion: "porcentaje de acciones"
    },
    predio: {
      labelPrincipal: "Bien inmueble",
      predios: "Bienes inmuebles",
      mPredios: "bienes inmuebles",
      predio: "bien inmueble",
      n_predial: "Clave catastral",
      fmi: "Matrícula inmobiliaria",
      destinoeconomico: "Destinación económica",
      tipopredio: "Tipo de predio",
      condicion: "Condición",
      areaconstruccion: "Área construcción",
      areaterreno: "Área terreno",
      vigencia: "Vigencia",
      avaluo: "Avalúo del predio",
      coefcopropiedad: "Coeficiente de copropiedad",
      nupre: "Código único eléctrico nacional",
      zona: "Zona"
    },
    derechoPropiedad: {
      entidadEmisora: "Ente emisor",
      numeroTitulo: "Número de fuente",
      ciudad: "Municipio",
      departamento: "Departamento",
      tipoTitulo: "Tipo de documento fuente administrativa",
      fechaTitulo: "Fecha documento fuente",
      fechaRegistro: "SNR fecha datos",
    }
  }
};
