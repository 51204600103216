import { createReducer, on } from "@ngrx/store";
import { setParametro, unsetParametro } from "./parametro.actions";
import { ParametrosModel } from "../models/parametros.model";

export interface State {
  parametro: ParametrosModel;
}

export const initialState: State = {
  parametro: null,
};

const _parametroReducer = createReducer(
  initialState,
  on(setParametro, (state, { parametro }) => ({
    ...state,
    parametro: { ...parametro },
  })),
  on(unsetParametro, (state) => ({
    ...state,
    parametro: initialState.parametro,
  }))
);

export function parametroReducer(state, action) {
  return _parametroReducer(state, action);
}
