import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AppSettings } from "../util/AppSettings";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad, CanActivateChild {
  constructor(private router: Router) {}

  canLoad() {
    if (AppSettings.getToken()) {
      // this.securityService.isAuth();
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    console.log(childRoute);
    console.log(state);
    return true;
  }
}
